import React from "react";
import { CContainer, CRow, CCol, CCard, CButton, CProgress, CProgressBar } from "@coreui/react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const ConfirmRegister = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer fluid className="p-0">
        <CRow className="d-flex justify-content-center align-items-center p-0 pb-5 m-0 w-100">
          <CCol md={10} className="d-flex flex-column h-100">
            <CCard className="p-5 d-flex flex-column align-items-center">
              <CRow className="mb-3 justify-content-center w-100 p-3">
                <CCol xxl={10} lg={10} md={12} sm={12} xs={12}>
                  <CProgress className="p-0" variant="striped" color="success" value={90}>
                    <CProgressBar>90%</CProgressBar>
                  </CProgress>
                </CCol>
              </CRow>
              <h1 className="h2 pe-0 text-center">
                SEU CADASTRO JÁ ESTÁ EM NOSSA PLATAFORMA!
              </h1>
              <p className="mb-2 fs-4 text-center">Falta 1 passo para você ter acesso</p>
              <p className="mb-2 fs-4 fw-semibold text-center">ASSISTA ESSE VIDEO ABAIXO ANTES DE CONTINUAR</p>
              <CRow className="col-12 col-sm-11 col-md-10 col-lg-8 col-xxl-6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/1PFmVc6-dOA?si=90MgJyRucghW51Rv"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </CRow>
              <p className="mt-3 mb-2 text-center">
                Acesse seu e-mail para validar o seu cadastro
              </p>
              <CButton
                style={{ width: "fit-content" }}
                onClick={() => navigate("/login")}
              >
                Voltar ao Login
              </CButton>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ConfirmRegister;
